import * as FeatherIcons from 'vue-feather-icons';

export default {
    items: [
        {
            name: 'Dashboard',
            type: 'dashboard',
            route: 'dashboard',
            color: 'navy',
            group: 'general',
            iconComponent: FeatherIcons.GridIcon,
        },
        {
            name: 'Content',
            children: [
                { name: 'Layouts', route: 'layouts' },
                { name: 'Pages', route: 'pages', type: 'page' },
                { name: 'Panel library', route: 'panels', type: 'panel' },
                // { name: 'Quick Links', route: 'quick_links', type: 'quick_link' },
            ],
            // iconName: '',
            color: 'navy',
            group: 'general',
            iconComponent: FeatherIcons.LayersIcon,
        },
        {
            name: 'Assets',
            route: 'assets/0',
            type: 'asset',
            // iconName: '',
            color: 'navy',
            group: 'general',
            iconComponent: FeatherIcons.ImageIcon,
        },
        // {
        //     name: 'Latest News',
        //     children: [
        //         { name: 'Posts', route: 'news_posts', type: 'news_post' },
        //         { name: 'Categories', route: 'news_categories', type: 'news_category' },
        //     ],
        //     // iconName: '',
        //     color: 'navy',
        //     group: 'media',
        //     iconComponent: FeatherIcons.FileTextIcon,
        // },
        {
            name: 'Accommodation',
            route: 'accommodations',
            type: 'accommodation',
            // iconName: '',
            color: 'navy',
            group: 'media',
            iconComponent: FeatherIcons.MapPinIcon,
        },
        {
            name: 'Banners',
            route: 'banners',
            type: 'banner',
            iconClass: 'kt-menu__link-icon flaticon2-chat-2',
            // iconName: '',
            color: 'navy',
            group: 'media',
            iconComponent: FeatherIcons.MessageSquareIcon,
        },
        {
            name: 'Keydates',
            route: 'keydates',
            type: 'keydate',
            iconClass: 'kt-menu__link-icon flaticon2-chat-2',
            // iconName: '',
            color: 'navy',
            group: 'media',
            iconComponent: FeatherIcons.CalendarIcon,
        },
        {
            name: 'Sponsors',
            children: [
                { name: 'Sponsors', route: 'sponsors', type: 'sponsor' },
                { name: 'Categories', route: 'sponsor_categories', type: 'sponsor_category' },
            ],
            // iconName: '',
            color: 'navy',
            group: 'media',
            iconComponent: FeatherIcons.OctagonIcon,
        },
        {
            name: 'Speakers',
            children: [
                { name: 'Speakers', route: 'speakers', type: 'speaker' },
                { name: 'Categories', route: 'speaker_categories', type: 'speaker_category' },
            ],
            // iconName: '',
            color: 'navy',
            group: 'media',
            iconComponent: FeatherIcons.MicIcon,
        },
        // {
        //     name: 'Customers',
        //     ...{ name: 'Customers', route: 'customers', type: 'customer' },
        //     // iconName: '',
        //     color: 'navy',
        //     group: 'client',
        //     iconComponent: FeatherIcons.UsersIcon,
        // },
        {
            name: 'Enquiries',
            children: [
                { name: 'Enquiries', route: 'enquiries', type: 'enquiry' },
                { name: 'Subscribers', route: 'subscribers', type: 'subscriber' },
            ],
            // iconName: '',
            color: 'navy',
            group: 'client',
            iconComponent: FeatherIcons.MailIcon,
        },
        {
            name: 'Accounts',
            children: [
                { name: 'Users', route: 'users' },
                { name: 'Roles', route: 'roles' },
            ],
            // iconName: '',
            color: 'navy',
            group: 'settings',
            iconComponent: FeatherIcons.LockIcon,
        },
        {
            name: 'Settings',
            children: [
                { name: 'Settings', route: 'settings', type: 'setting' },
                { name: 'Redirect URLs', route: 'redirect_urls', type: 'redirect_url' },
            ],
            // iconName: '',
            color: 'navy',
            group: 'settings',
            iconComponent: FeatherIcons.SettingsIcon,
        }
    ],
    defaultItem: 'Content',
}
