import menu from './menu';
import notifications from './notifications';

export default {
    rootPath: window.env.APP_URL,
    cmsPath: '/admin',
    cmsTitle: window.env.APP_NAME,
    routes: {
        default: '/dashboard',
        endpoints: {
            '^/pages': {
                params: { filter: 'parent_id:$null' },
            },
            '^/panels': {
                params: { filter: 'content_id:$null' },
            },
            '^/categories': {
                params: { filter: 'parent_id:$null' },
            },
            '^/sponsor_categories': {
                params: {
                    // sort: 'position:desc'
                }
            },


        }
    },
    file: {
        extensions: {
            img: ['png', 'jpg', 'jpeg', 'gif', 'svg'],
            doc: ['doc', 'docx'],
            pdf: ['pdf'],
            xls: ['xls', 'xlsx'],
            mp4: ['mp4', 'webm'],
            csv: ['csv'],
            mod: ['mod'],
            dwg: ['dwx', 'dwg'],
        }
    },
    menu,
    notifications,
    inboxActive: false,
}
