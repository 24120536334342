import _ from 'lodash';
import getHelpers from '../helpers';
const api = getHelpers('api');

// initial state
const state = {
    ...window.store,
    notifications: [],
    project: null,
    projects: null,
    load_calculations: null,
    timestamp: Date.now(),
}

// getters
const getters = {
    project: (state) => state.project,
    customer: (state) => state.customer,
    projects: (state) => state.projects,
    load_calculations: (state) => state.load_calculations,
    notifications: (state) => state.notifications,
    timestamp: (state) => state.timestamp,
    pathNodes: (state) => state.pathNodes,
    route: (state) => (name, params) => {
        let path = '/' + _.get(state, ['routes', name]);

        _.forEach(params, (value, key) => {
            path = _.replace(path, new RegExp(`{${key}[?]?}`), value);
        })

        return path;
    }
}

const actions = {
    registerCustomer({ commit }, data) {
        return api
            .postData(`/../register`, {}, data)
            .then(res => {
                commit('addNotification', {
                    type: 'message',
                    message: 'Your account has been created',
                });
                commit('updateProfile', res);
                return res;
            })
    },

    updateProfile({ commit }, data) {
        return api
            .patchData(`/my/profile`, {}, data)
            .then(res => {
                commit('addNotification', {
                    type: 'message',
                    message: 'Profile update success',
                });
                commit('updateProfile', res);
                return res;
            })
    },

    updateCustomer({ commit, state }, data) {
        const customerId = _.get(state, 'customer.id');
        return api
            .patchData(`/my/customer/${customerId}`, {}, { origin: data })
            .then(res => {
                commit('addNotification', {
                    type: 'message',
                    message: 'Profile update success',
                });
                commit('updateCustomer', res);
            })
    },

    getProjects({ commit }, payload) {
        return api.getData(`/my/projects`)
            .then(res => {
                commit('setProjects', res);
                return res;
            })
    },

    setProject({ commit }, payload) {
        commit('setProject', payload);
    },

    setProjects({ commit }, payload) {
        commit('setProjects', payload);
    },

    createProject({ commit }, payload) {
        let project;

        return api.postData(`/my/projects`, { ...payload })
            .then((res) => {
                project = res;
                return api.getData(`/my/projects`);
            })
            .then(res => {
                commit('addNotification', {
                    type: 'message',
                    message: 'Project has been created',
                });
                commit('setProjects', res);
                return project;
            })
            .catch(e => {
                commit('addNotification', {
                    type: 'error',
                    message: _.get(e, 'response.data.message', 'Could not create project'),
                });
                throw e;
            });
    },

    updateProject({ commit }, payload) {
        return api.patchData(`/my/projects/${payload.id}`, {}, { ...payload })
            .then(res => {
                commit('addNotification', {
                    type: 'message',
                    message: 'Item has been updated',
                });
                commit('updateProjects', [
                    { id: res.id, $updated: res }
                ]);
                return res;
            })
            .catch(e => {
                commit('addNotification', {
                    type: 'error',
                    message: _.get(e, 'response.data.message', 'Could not update project'),
                });
                throw e;
            });
    },

    deleteProject({ commit }, payload) {
        return api.delData(`/my/projects/${payload.id}`)
            .then(res => {
                commit('addNotification', {
                    type: 'message',
                    message: 'Item has been deleted',
                });
                commit('updateProjects', [
                    { id: res.id, $deleted: true }
                ]);
                return res;
            })
    },

    getLoadCalculations({ commit }, payload) {
        const { project_id } = payload;
        return api.getData(`/my/load_calculations`, { filter: `project_id:${project_id}` })
            .then(res => {
                commit('setLoadCalculations', res);
                return res;
            })
    },

    setLoadCalculations({ commit }, loadCalculations) {
        commit('setLoadCalculations', loadCalculations);
    },

    createLoadCalculation({ commit }, payload) {
        return api.postData(`/my/load_calculations`, {}, { ...payload })
            .then(res => {
                commit('addNotification', {
                    type: 'message',
                    message: 'Item has been added to project',
                });
                commit('updateLoadCalculations', [
                    { id: res.id, $created: res }
                ]);
                return res;
            })
            .catch(e => {
                commit('addNotification', {
                    type: 'error',
                    message: _.get(e, 'response.data.message', 'Could not create load calculations'),
                });
                throw e;
            });
    },

    updateLoadCalculation({ commit }, payload) {
        return api.patchData(`/my/load_calculations/${payload.id}`, {}, { ...payload })
            .then(res => {
                commit('addNotification', {
                    type: 'message',
                    message: 'Item has been updated',
                });
                commit('updateLoadCalculations', [
                    { id: res.id, $updated: res }
                ]);
                return res;
            })
            .catch(e => {
                commit('addNotification', {
                    type: 'error',
                    message: _.get(e, 'response.data.message', 'Could not add cart item'),
                });
                throw e;
            });
    },

    deleteLoadCalculation({ commit }, payload) {
        return api.delData(`/my/load_calculations/${payload.id}`)
            .then(res => {
                commit('addNotification', {
                    type: 'message',
                    message: 'Item has been deleted',
                });
                commit('updateLoadCalculations', [
                    { id: res.id, $deleted: true }
                ]);
                return res;
            })
    },

    addNotification({ commit }, payload) {
        commit('addNotification', payload);
    },

    createDeliveryAddress({ commit }, data) {
        return api.postData('/my/account/delivery_addresses', {}, data)
            .then(res => {
                commit('addNotification', {
                    type: 'message',
                    message: 'Delivery address has been created',
                });
                commit('addDeliveryAddress', res);
                return res;
            })
    },

    updateDeliveryAddresses({ commit }, payload) {
        const { data } = payload;

        return api.putData('/my/account/delivery_addresses', {}, { data })
            .then(res => {
                commit('addNotification', {
                    type: 'message',
                    message: 'Delivery address has been updated',
                });
                commit('updateDeliveryAddresses', res);
                return res;
            })
    },
}

// mutations
const mutations = {
    updateProfile(state, response) {
        _.set(state, 'customer', response);
        _.set(state, 'timestamp', Date.now());
    },

    updateCustomer(state, response) {
        _.set(state, 'customer', response);
        _.set(state, 'timestamp', Date.now());
    },

	setProject(state, response) {
        _.set(state, 'project', response);
        _.set(state, 'timestamp', Date.now());
	},

	setProjects(state, response) {
        _.set(state, 'projects', response);
        _.set(state, 'timestamp', Date.now());
	},

    updateProjects(state, data) {
        let items = _.get(state, 'projects.data', []);

        _.forEach(data, (item) => {
            /*||*/ if (_.has(item, '$created')) {
                items = _.concat(items, item.$created);

            } else if (_.has(item, '$updated')) {
                const idx = _.findIndex(items, (it) => it.id == item.id);
                items[idx] = item.$updated;

            } else if (_.has(item, '$deleted')) {
                items = _.reject(items, (it) => it.id == item.id);
            }
        })

        _.set(state, 'projects.data', items);
        _.set(state, 'timestamp', Date.now());
    },

	setLoadCalculations(state, response) {
        _.set(state, 'load_calculations', response);
        _.set(state, 'timestamp', Date.now());
	},

    updateLoadCalculations(state, data) {
        let items = _.get(state, 'load_calculations.data', []);

        _.forEach(data, (item) => {
            /*||*/ if (_.has(item, '$created')) {
                items = _.concat(items, item.$created);

            } else if (_.has(item, '$updated')) {
                const idx = _.findIndex(items, (it) => it.id == item.id);
                items[idx] = item.$updated;

            } else if (_.has(item, '$deleted')) {
                items = _.reject(items, (it) => it.id == item.id);
            }
        })

        _.set(state, 'load_calculations.data', items);
        _.set(state, 'timestamp', Date.now());
    },

    addDeliveryAddress(state, response) {
        let data = _.get(state, 'customer.included.delivery_addresses.data', []);
        data = _.concat(data, response);

        _.set(state, 'customer.included.delivery_addresses.data', data);
        _.set(state, 'timestamp', Date.now());
    },

    updateDeliveryAddresses(state, response) {
        const { updated, deleted, attached, detached } = response.meta;

        let data = _.get(state, 'customer.included.delivery_addresses.data', []);

        // replacing updated items
        data = _.map(data, item => {
            const replacingItem = _.find(response.data, (itemData => itemData.id == item.id));
            return replacingItem || item;
        });

        // filter out deleted items
        data = _.filter(data, item => {
            return _.indexOf(deleted, item.id) < 0;
        });

        // add items attached
        data = _.concat(data, _.filter(response.data, item => _.indexOf(attached, item.id) >= 0));

        // filter out detached items
        data = _.filter(data, item => _.indexOf(detached, item.id) < 0);

        _.set(state, 'customer.included.delivery_addresses.data', data);
        _.set(state, 'timestamp', Date.now());
    },

    addNotification(state, payload) {
        _.set(state, 'notifications', _.concat(state.notifications, [payload]));
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
